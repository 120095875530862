import { AuthenticationTriggerWrapper } from '@/components/buttons/AuthenticationTriggerWrapper';
import { env } from '@/env.mjs';
import { useMarketplaceChain } from '@/hooks/use-marketplace-chain';
import { useAuthentication } from '@/hooks/useAuthentication';
import { ReservoirAction } from '@/utils/reservoir';
import { routes } from '@/utils/routes';
import { CollectModal, CollectStep } from '@sphere/reservoir-kit-ui';
import { useRouter } from 'next/router';
import { ReactElement, useState, type ComponentPropsWithoutRef, type FC } from 'react';
import { type SWRResponse } from 'swr';
import ActionDisabledTooltip from './ActionDisabledTooltip';

type Props = {
  collectionId?: string;
  tokenId?: string;
  mutate?: SWRResponse['mutate'];
  openState?: ComponentPropsWithoutRef<typeof CollectModal>['openState'];
  trigger: ReactElement;
};

const Mint: FC<Props> = ({ collectionId, tokenId, mutate, openState, trigger }) => {
  const router = useRouter();
  const { authenticate } = useAuthentication();
  const marketplaceChain = useMarketplaceChain();
  const defaultOpenState = useState(false);

  const WrappedTrigger = (
    <ActionDisabledTooltip action={ReservoirAction.Collect}>{trigger}</ActionDisabledTooltip>
  );

  return (
    <AuthenticationTriggerWrapper trigger={WrappedTrigger}>
      <CollectModal
        trigger={WrappedTrigger}
        collectionId={collectionId}
        tokenId={tokenId}
        mode="mint"
        openState={openState || defaultOpenState}
        chainId={marketplaceChain.id}
        normalizeRoyalties={env.NEXT_PUBLIC_NORMALIZE_ROYALTIES}
        onConnectWallet={authenticate}
        onClose={(data, currentStep) => {
          if (mutate && currentStep == CollectStep.Complete) mutate();
        }}
        onGoToToken={data => {
          const mintedTokenId = data.stepData?.currentStepItem.transfersData?.[0].token?.tokenId;
          router.push(
            routes.nft.detail(
              collectionId ?? '',
              mintedTokenId ?? '',
              marketplaceChain.routePrefix,
            ),
          );
        }}
        onAddFunds={() => marketplaceChain.onAddFunds(openState || defaultOpenState)}
      />
    </AuthenticationTriggerWrapper>
  );
};

export default Mint;
